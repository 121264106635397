<template>
  <topping/>
  <div class="praktisk">
    <section class="wrapper container hytte">
        <div class="col5 pushcol2">
            <h5>
                {{ form.hyttetitle }}
            </h5>
            <p>
                {{ form.hyttetext }}
            </p>
        </div>
        <div class="col3">
            <img src="../assets/images/praktisk.jpg" alt="">
        </div>
    </section>
    <section class="wrapper map">
        <div class="col8 pushcol2">
            <img src="../assets/images/map.jpg" alt="">
        </div>
    </section>
    <section class="wrapper container uniform">
        <div class="col4 pushcol2">
            <svg id="UNIFORMSVEJLEDNING_FLAT_ART" data-name="UNIFORMSVEJLEDNING FLAT ART" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 605.5 432.856">
                <g id="Rectangle_13" data-name="Rectangle 13">
                    <rect id="Rectangle_122" data-name="Rectangle 122" width="605.5" height="432.5" fill="#036"/>
                </g>
                <g id="Rectangle_14" data-name="Rectangle 14" transform="translate(232.495 320.595)">
                    <rect id="Rectangle_123" data-name="Rectangle 123" width="137.452" height="111.075" transform="translate(0.592 0.592)" fill="#1a1a1a"/>
                    <path id="Path_423" data-name="Path 423" d="M334.847,382.82H196.21V270.56H334.847Zm-137.452-1.185H333.662V271.745H197.395Z" transform="translate(-196.21 -270.56)" fill="gray"/>
                </g>
                <ellipse id="Ellipse_31" data-name="Ellipse 31" cx="21.329" cy="17.182" rx="21.329" ry="17.182" transform="translate(152.513 349.875)" fill="#f2f2f2"/>
                <path id="Path_424" data-name="Path 424" d="M150.131,330.318c-12.086,0-21.921-7.975-21.921-17.774s9.835-17.774,21.921-17.774,21.921,7.975,21.921,17.774S162.229,330.318,150.131,330.318Zm0-34.363c-11.435,0-20.736,7.441-20.736,16.589s9.3,16.589,20.736,16.589,20.736-7.441,20.736-16.589S161.566,295.955,150.131,295.955Z" transform="translate(23.71 54.512)" fill="gray"/>
                <path id="Path_425" data-name="Path 425" d="M137.97,299.111V286.16h20.724v12.951" transform="translate(25.515 52.92)" fill="#f2f2f2"/>
                <path id="Path_426" data-name="Path 426" d="M159.379,299.2h-1.185V286.845h-19.54V299.2H137.47V285.66h21.909Z" transform="translate(25.423 52.828)" fill="gray"/>
                <ellipse id="Ellipse_32" data-name="Ellipse 32" cx="21.329" cy="17.182" rx="21.329" ry="17.182" transform="translate(405.851 349.875)" fill="#f2f2f2"/>
                <path id="Path_427" data-name="Path 427" d="M363.931,330.318c-12.1,0-21.921-7.975-21.921-17.774s9.823-17.774,21.921-17.774,21.921,7.975,21.921,17.774S376.018,330.318,363.931,330.318Zm0-34.363c-11.435,0-20.736,7.441-20.736,16.589s9.3,16.589,20.736,16.589,20.736-7.441,20.736-16.589S375.354,295.955,363.931,295.955Z" transform="translate(63.248 54.512)" fill="gray"/>
                <path id="Path_428" data-name="Path 428" d="M351.76,299.111V286.16h20.724v12.951" transform="translate(65.052 52.92)" fill="#f2f2f2"/>
                <path id="Path_429" data-name="Path 429" d="M373.169,299.2h-1.185V286.845h-19.54V299.2H351.26V285.66h21.909Z" transform="translate(64.959 52.828)" fill="gray"/>
                <path id="Path_430" data-name="Path 430" d="M150.228,208.162c-19.1,34.908-23.7,74.651-20.535,113.114h44.162c1.469-15.9,3.436-34.067,18.426-44.423,6.719-4.645,14.895-7.975,19.978-13.958,4.55-5.38,6-12.2,7.323-18.757,3.365-16.672,31.827-97.615,7.986-103.658C201.82,133.949,158.12,192.924,150.228,208.162Z" transform="translate(23.815 25.886)" fill="#005c8c"/>
                <path id="Path_431" data-name="Path 431" d="M129.225,321.96v-.545c-3.614-43.677,3.318-81.843,20.606-113.445,8.295-15.914,51.936-74.58,78-67.98a10.664,10.664,0,0,1,6.742,5.308c8.911,15.4-3.152,58.441-10.356,84.13-1.848,6.612-3.318,11.849-3.934,14.883-1.268,6.28-2.7,13.413-7.453,19.018-3.484,4.112-8.449,7.015-13.236,9.823-2.311,1.351-4.68,2.737-6.861,4.242-15.072,10.416-16.79,29.043-18.165,44v.533Zm94.321-181.295c-25.429,0-65.23,53.44-72.707,67.861h0c-17.11,31.27-24.007,69.022-20.523,112.249h43.072c1.4-15.108,3.282-33.711,18.627-44.316,2.216-1.529,4.609-2.939,6.932-4.289,4.74-2.761,9.574-5.617,12.928-9.574,4.562-5.368,5.925-12.335,7.2-18.485.628-3.1,2.1-8.295,3.958-14.966,6.683-23.865,19.137-68.276,10.475-83.241a9.539,9.539,0,0,0-6.008-4.74,16.329,16.329,0,0,0-3.958-.5Z" transform="translate(23.726 25.793)" fill="gray"/>
                <path id="Path_432" data-name="Path 432" d="M376.143,208.162c19.1,34.908,23.7,74.651,20.535,113.114H352.515c-1.469-15.9-3.436-34.067-18.426-44.423-6.719-4.645-14.895-7.975-19.978-13.958-4.55-5.38-6-12.2-7.323-18.757-3.365-16.672-31.827-97.615-7.986-103.658C324.551,133.949,368.251,192.924,376.143,208.162Z" transform="translate(53.513 25.886)" fill="#005c8c"/>
                <path id="Path_433" data-name="Path 433" d="M397.322,321.955H352.057v-.533c-1.375-14.954-3.093-33.581-18.165-44-2.18-1.5-4.55-2.891-6.861-4.242-4.74-2.808-9.752-5.711-13.236-9.823-4.74-5.6-6.185-12.738-7.453-19.018-.616-3.057-2.085-8.295-3.934-14.883-7.2-25.713-19.267-68.726-10.356-84.13a10.665,10.665,0,0,1,6.742-5.308c26.068-6.6,69.757,52.066,78,67.98,17.241,31.626,24.173,69.733,20.559,113.386Zm-44.174-1.185H396.22c3.5-43.214-3.4-80.978-20.511-112.237h0c-7.88-15.191-51.639-73.726-76.677-67.387a9.539,9.539,0,0,0-6.008,4.74c-8.662,14.966,3.792,59.377,10.475,83.241,1.86,6.636,3.33,11.849,3.958,14.966,1.244,6.15,2.642,13.117,7.2,18.485,3.353,3.958,8.223,6.813,12.928,9.574,2.37,1.351,4.74,2.761,6.932,4.289,15.345,10.581,17.229,29.209,18.627,44.316Z" transform="translate(53.426 25.799)" fill="gray"/>
                <circle id="Ellipse_33" data-name="Ellipse 33" cx="71.096" cy="71.096" r="71.096" transform="translate(233.68 170.002)" fill="#005c8c"/>
                <path id="Path_434" data-name="Path 434" d="M339.993,214.747a76.44,76.44,0,0,1-15.155,16.317c-15.262,12.288-35.8,19.812-58.31,19.812-22.834,0-43.546-7.726-58.82-20.286A76.6,76.6,0,0,1,192.67,214" transform="translate(35.631 39.575)" fill="#005c8c"/>
                <path id="Path_435" data-name="Path 435" d="M266.612,251.512c-22.158,0-43.179-7.252-59.247-20.416a77.127,77.127,0,0,1-15.155-16.672l.983-.664a75.942,75.942,0,0,0,14.918,16.423c15.807,13.034,36.555,20.144,58.441,20.144,21.613,0,42.16-6.991,57.872-19.682a75.457,75.457,0,0,0,15.037-16.186l.983.664a77.021,77.021,0,0,1-15.274,16.447C309.329,244.426,288.509,251.512,266.612,251.512Z" transform="translate(35.546 39.531)" fill="gray"/>
                <path id="Path_436" data-name="Path 436" d="M345.308,214.766v2.263l-5,38.641-1.766,76.44H195.616l-1.789-76.866-4.977-36.709V214.02" transform="translate(34.924 39.579)" fill="#005c8c"/>
                <path id="Path_437" data-name="Path 437" d="M339.2,332.8H195.128l-1.8-77.447L188.35,218.7v-4.609a.592.592,0,1,1,1.185,0v4.515l4.977,36.626,1.777,76.357H338.054l1.754-75.836,5-38.712v-2.18a.592.592,0,0,1,.592-.592.6.6,0,0,1,.592.592v2.263l-5,38.724Z" transform="translate(34.832 39.483)" fill="gray"/>
                <path id="Path_438" data-name="Path 438" d="M234.685,242.37H208.344c-6.695,0-12.134-6.991-12.134-15.594V203.232a19.3,19.3,0,0,1,.367-3.756,3.2,3.2,0,0,1,.19-.865l.107-.45h49.269l.118.45c.071.284.13.569.19.865a19.329,19.329,0,0,1,.355,3.756v23.545C246.807,235.379,241.368,242.37,234.685,242.37ZM197.81,199.345l-.071.308a19.66,19.66,0,0,0-.344,3.555v23.545c0,7.951,4.917,14.409,10.949,14.409h26.341c6.031,0,10.937-6.458,10.937-14.409V203.232a18.771,18.771,0,0,0-.332-3.555.581.581,0,0,0-.083-.367Z" transform="translate(36.285 36.646)" fill="gray"/>
                <path id="Path_439" data-name="Path 439" d="M234.3,218.669H208.608c-3.306,0-6.375-2.109-8.662-5.925a27.064,27.064,0,0,1-3.436-12.9V198.17h49.933v1.694C246.064,210.4,240.743,218.669,234.3,218.669Zm-36.614-19.326v.45a25.939,25.939,0,0,0,3.27,12.323c2.062,3.46,4.74,5.368,7.643,5.368h25.7c5.806,0,10.605-7.761,10.925-17.667v-.474Z" transform="translate(36.341 36.648)" fill="gray"/>
                <path id="Path_440" data-name="Path 440" d="M309.823,242.37H283.482c-6.683,0-12.122-6.991-12.122-15.594V203.232a19.293,19.293,0,0,1,.355-3.756,3.2,3.2,0,0,1,.19-.865l.107-.45h49.281l.107.45c.071.284.13.569.19.865a19.326,19.326,0,0,1,.355,3.756v23.545C321.945,235.379,316.506,242.37,309.823,242.37Zm-36.875-43.025a.554.554,0,0,1-.059.308,18.664,18.664,0,0,0-.344,3.555v23.545c0,7.951,4.906,14.409,10.937,14.409h26.341c6.031,0,10.937-6.458,10.937-14.409V203.232a17.77,17.77,0,0,0-.332-3.555l-.071-.367Z" transform="translate(50.183 36.646)" fill="gray"/>
                <path id="Path_441" data-name="Path 441" d="M309.438,218.669H283.748c-3.294,0-6.375-2.109-8.662-5.925a27.324,27.324,0,0,1-3.436-12.9V198.17h49.933v1.694C321.2,210.4,315.907,218.669,309.438,218.669Zm-36.614-19.326v.45a25.939,25.939,0,0,0,3.27,12.323c2.062,3.46,4.74,5.368,7.643,5.368h25.689c5.806,0,10.605-7.761,10.925-17.667v-.474Z" transform="translate(50.237 36.648)" fill="gray"/>
                <path id="Path_442" data-name="Path 442" d="M264.207,344.436h-16V153.271l8-3.851,8,3.851Zm-14.812-1.185h13.627V154.006l-6.766-3.27-6.813,3.27Z" transform="translate(45.902 27.632)" fill="gray"/>
                <path id="Path_443" data-name="Path 443" d="M264.122,163.875H247a8.934,8.934,0,0,1,4.609-1.185h7.915a9.088,9.088,0,0,1,4.6,1.185Z" transform="translate(45.678 30.086)" fill="#008700"/>
                <path id="Path_444" data-name="Path 444" d="M245.16,164.575l1.849-1.1a9.7,9.7,0,0,1,4.917-1.3h7.915a9.681,9.681,0,0,1,4.929,1.292l1.837,1.1Zm6.766-1.185h0Z" transform="translate(45.338 29.99)" fill="gray"/>
                <path id="Path_445" data-name="Path 445" d="M262.07,121c-27.8,0-50.36,12.548-50.36,28.035,0,13.295,16.589,24.433,38.937,27.313H273.5c22.312-2.879,38.925-14.018,38.925-27.313C312.429,133.513,289.892,121,262.07,121Zm8.591,50.62h0a9.089,9.089,0,0,0-4.621-1.185h-7.915a8.934,8.934,0,0,0-4.609,1.185c-15.771-2.145-27.49-10.06-27.49-19.5,0-11.079,16.151-20.073,36.057-20.073s36.069,8.994,36.069,20.073c-.012,9.42-11.719,17.336-27.49,19.468Z" transform="translate(39.152 22.377)" fill="#008700"/>
                <path id="Path_446" data-name="Path 446" d="M250.739,177c-23.3-3.01-39.529-14.48-39.529-27.905,0-15.783,22.857-28.628,50.952-28.628s50.952,12.845,50.952,28.628c0,13.425-16.222,24.884-39.446,27.893Zm11.423-55.348c-27.431,0-49.767,12.311-49.767,27.443,0,12.608,16.162,23.853,38.427,26.72H273.6c22.182-2.868,38.333-14.113,38.333-26.72,0-15.132-22.324-27.443-49.767-27.443Zm-8.449,50.656h-.2c-16.482-2.228-28-10.487-28-20.085,0-11.387,16.447-20.665,36.65-20.665s36.662,9.278,36.662,20.665c0,9.6-11.518,17.845-27.988,20.085h-.261l-.142-.083a8.413,8.413,0,0,0-4.313-1.185H258.2a8.52,8.52,0,0,0-4.313,1.185Zm8.449-39.6c-19.551,0-35.465,8.745-35.465,19.48,0,8.946,11,16.7,26.779,18.888a9.764,9.764,0,0,1,4.74-1.185h7.915a9.693,9.693,0,0,1,4.74,1.185c15.771-2.18,26.779-9.93,26.779-18.876C297.639,141.455,281.725,132.71,262.162,132.71Z" transform="translate(39.059 22.279)" fill="gray"/>
                <path id="Path_447" data-name="Path 447" d="M264.777,169.46h0c1.872,1.766,4.574,4.669,6.019,6.268a17.548,17.548,0,0,1,2.145,2.844c3.555,6.055,4.515,12.892,2.429,18.615l-.96,2.666a1.694,1.694,0,0,1-2.548.652l-2.927-1.694A28.557,28.557,0,0,1,255.9,182.79a14.35,14.35,0,0,1-.581-3.01c-.142-1.718-.367-4.858-.391-7.11Z" transform="translate(47.145 31.338)" fill="#008700"/>
                <path id="Path_448" data-name="Path 448" d="M273.047,201.542a2.773,2.773,0,0,1-1.386-.391l-2.927-1.694a29.031,29.031,0,0,1-13.319-16.364,14.776,14.776,0,0,1-.6-3.128c-.095-1.185-.367-4.74-.391-7.181v-.438l11.044-3.555v.569c2.24,2.145,5.273,5.474,5.865,6.138a18.037,18.037,0,0,1,2.216,2.939c3.709,6.268,4.609,13.224,2.477,19.113l-.96,2.666a2.014,2.014,0,0,1-1.185,1.185A2.239,2.239,0,0,1,273.047,201.542Zm-17.43-28.332c0,2.37.284,5.581.367,6.659a15.108,15.108,0,0,0,.557,2.879,27.905,27.905,0,0,0,12.785,15.689l2.927,1.683a1.4,1.4,0,0,0,1.185.166.829.829,0,0,0,.51-.5l.96-2.666c2.014-5.545,1.185-12.146-2.37-18.106a18.084,18.084,0,0,0-2.026-2.749c-1.611-1.777-4.041-4.361-5.747-6.008Z" transform="translate(47.05 31.215)" fill="gray"/>
                <path id="Path_449" data-name="Path 449" d="M256.671,172.678c0,2.287-.249,5.451-.391,7.169a13.471,13.471,0,0,1-.569,3.01,28.592,28.592,0,0,1-13.034,16.02l-2.951,1.694a1.683,1.683,0,0,1-2.548-.652l-1.007-2.666c-2.074-5.723-1.185-12.548,2.441-18.6a17.059,17.059,0,0,1,2.133-2.856c1.5-1.647,4.325-4.68,6.2-6.434Z" transform="translate(43.472 31.32)" fill="#008700"/>
                <path id="Path_450" data-name="Path 450" d="M238.718,201.573a2.371,2.371,0,0,1-.806-.142,1.979,1.979,0,0,1-1.185-1.185l-.972-2.666c-2.133-5.925-1.185-12.845,2.488-19.113a18.548,18.548,0,0,1,2.2-2.95c1.185-1.315,4.207-4.574,6.233-6.458l.261-.249,10.427,3.555v.427c0,1.694-.142,4.052-.391,7.2a14.536,14.536,0,0,1-.592,3.152,29.185,29.185,0,0,1-13.331,16.364l-2.915,1.683A2.809,2.809,0,0,1,238.718,201.573Zm8.5-31.436c-2.05,1.955-5.012,5.178-5.925,6.162a16.315,16.315,0,0,0-2.062,2.749c-3.555,5.925-4.408,12.56-2.37,18.106l.972,2.666a.782.782,0,0,0,.5.5,1.469,1.469,0,0,0,1.185-.154l2.915-1.694a27.869,27.869,0,0,0,12.785-15.688,13.757,13.757,0,0,0,.557-2.879c.213-2.832.344-5.083.379-6.707Z" transform="translate(43.391 31.218)" fill="gray"/>
                <path id="Path_451" data-name="Path 451" d="M267.427,168.283v.32a4.467,4.467,0,0,1-.735,2.109h0a6.079,6.079,0,0,1-1.446,1.529,8.9,8.9,0,0,1-5.3,1.659h-7.915a7.892,7.892,0,0,1-6.8-3.318,4.514,4.514,0,0,1-.664-1.991v-.32a5.048,5.048,0,0,1,2.192-3.97,6.341,6.341,0,0,1,.664-.427,8.934,8.934,0,0,1,4.609-1.185h7.915a9.088,9.088,0,0,1,4.621,1.185h0A5.273,5.273,0,0,1,267.427,168.283Z" transform="translate(45.229 30.086)" fill="#008700"/>
                <path id="Path_452" data-name="Path 452" d="M260.043,174.593h-7.915a8.52,8.52,0,0,1-7.311-3.555,5.119,5.119,0,0,1-.747-2.263v-.355a5.557,5.557,0,0,1,2.429-4.432,7.7,7.7,0,0,1,.723-.486,9.74,9.74,0,0,1,4.906-1.292h7.915a9.669,9.669,0,0,1,4.74,1.185h0l.142.083a5.806,5.806,0,0,1,3.14,4.917v.367a4.847,4.847,0,0,1-.723,2.228h0l-.095.154a6.874,6.874,0,0,1-1.588,1.683A9.479,9.479,0,0,1,260.043,174.593Zm-7.915-11.245a8.52,8.52,0,0,0-4.313,1.185,5.277,5.277,0,0,0-.6.391,4.42,4.42,0,0,0-1.955,3.5,1.905,1.905,0,0,0,0,.261,3.756,3.756,0,0,0,.581,1.742,7.323,7.323,0,0,0,6.292,3.033h7.915a8.164,8.164,0,0,0,4.929-1.54,5.581,5.581,0,0,0,1.256-1.28l.059-.107a3.851,3.851,0,0,0,.64-1.837v-.273a4.609,4.609,0,0,0-2.453-3.827l-.119-.071a8.413,8.413,0,0,0-4.313-1.185Z" transform="translate(45.136 29.998)" fill="gray"/>
                <path id="Path_453" data-name="Path 453" d="M324.61,139.97l-23.2,27.562-36.851-15.416-37.61,15.416L203.51,140.847" transform="translate(37.635 25.885)" fill="#005c8c"/>
                <path id="Path_454" data-name="Path 454" d="M301.657,168.313l-37.029-15.5L226.852,168.3l-23.722-27,.889-.782,23.165,26.365,37.444-15.345,36.685,15.345,22.917-27.23.9.758Z" transform="translate(37.565 25.826)" fill="gray"/>
                <path id="Path_455" data-name="Path 455" d="M203.5,144.174h0a86.5,86.5,0,0,1,28-16.482,98.207,98.207,0,0,1,67.115.4A86.394,86.394,0,0,1,324.6,143.333c.45.379.877.758,1.315,1.185h0" transform="translate(37.634 22.558)" fill="#005c8c"/>
                <path id="Path_456" data-name="Path 456" d="M325.6,144.979c-.427-.391-.841-.758-1.292-1.185a85.386,85.386,0,0,0-25.784-15.108,97.626,97.626,0,0,0-66.712-.4,85.517,85.517,0,0,0-27.633,16.257v.32h-.592v-.592H203v-.592h.379a86.666,86.666,0,0,1,28.059-16.459,98.835,98.835,0,0,1,67.541.4,86.714,86.714,0,0,1,26.069,15.321c.45.379.889.77,1.327,1.185Z" transform="translate(37.541 22.463)" fill="gray"/>
                <path id="Path_457" data-name="Path 457" d="M260.054,152.911c-18.769,0-33.474-6.209-33.474-14.136s14.705-14.124,33.474-14.124,33.474,6.2,33.474,14.124S278.824,152.911,260.054,152.911Zm0-27.076c-17.774,0-32.289,5.806-32.289,12.939s14.492,12.951,32.289,12.951,32.289-5.806,32.289-12.951S277.864,125.835,260.054,125.835Z" transform="translate(41.902 23.052)" fill="gray"/>
                <path id="Path_458" data-name="Path 458" d="M272.66,150.5V124.51H241.07V150.5" transform="translate(44.581 23.026)" fill="#fff"/>
                <path id="Path_459" data-name="Path 459" d="M273.345,150.588H272.16V125.195H241.755v25.393H240.57V124.01h32.775Z" transform="translate(44.489 22.933)" fill="gray"/>
                <path id="Path_460" data-name="Path 460" d="M272.66,124.51V150.5a146.618,146.618,0,0,1-15.8.818,146.3,146.3,0,0,1-15.8-.818V124.51Z" transform="translate(44.581 23.026)" fill="#fff"/>
                <path id="Path_461" data-name="Path 461" d="M256.958,152a149.117,149.117,0,0,1-15.866-.818l-.521-.059V124h32.775v27.111l-.533.059a148.708,148.708,0,0,1-15.854.829Zm-15.2-1.931a151.589,151.589,0,0,0,30.405,0V125.185H241.755Z" transform="translate(44.489 22.932)" fill="gray"/>
                <path id="Path_462" data-name="Path 462" d="M272.66,133v11.067c-5.012.237-10.309.355-15.8.355s-10.795-.118-15.8-.355V133Z" transform="translate(44.581 24.596)" fill="#4d4d4d"/>
                <path id="Path_463" data-name="Path 463" d="M256.958,145.078c-5.439,0-10.771-.118-15.831-.355h-.557V132.47h32.775V144.7h-.569C267.717,144.947,262.408,145.078,256.958,145.078Zm-15.2-1.517c9.74.438,20.665.438,30.405,0v-9.906H241.755Z" transform="translate(44.489 24.498)" fill="gray"/>
                <circle id="Ellipse_34" data-name="Ellipse 34" cx="58.062" cy="58.062" r="58.062" transform="translate(244.937 44.992)" fill="#fff"/>
                <path id="Path_464" data-name="Path 464" d="M264.864,154.778A58.654,58.654,0,1,1,323.518,96.16,58.654,58.654,0,0,1,264.864,154.778Zm0-116.123a57.469,57.469,0,1,0,57.469,57.5,57.469,57.469,0,0,0-57.469-57.5Z" transform="translate(38.135 6.929)" fill="gray"/>
                <circle id="Ellipse_35" data-name="Ellipse 35" cx="2.37" cy="2.37" r="2.37" transform="translate(297.667 117.865)" fill="#1a1a1a"/>
                <path id="Path_465" data-name="Path 465" d="M253.672,104.925a2.962,2.962,0,1,1,2.962-2.962A2.962,2.962,0,0,1,253.672,104.925Zm0-4.74a1.777,1.777,0,1,0,1.777,1.777,1.777,1.777,0,0,0-1.777-1.777Z" transform="translate(46.364 18.308)" fill="#1a1a1a"/>
                <circle id="Ellipse_36" data-name="Ellipse 36" cx="2.37" cy="2.37" r="2.37" transform="translate(333.215 117.865)" fill="#1a1a1a"/>
                <path id="Path_466" data-name="Path 466" d="M283.672,104.925a2.962,2.962,0,1,1,2.962-2.962,2.962,2.962,0,0,1-2.962,2.962Zm0-4.74a1.777,1.777,0,1,0,1.777,1.777,1.777,1.777,0,0,0-1.777-1.777Z" transform="translate(51.912 18.308)" fill="#1a1a1a"/>
                <path id="Path_467" data-name="Path 467" d="M262.731,115.721a1.339,1.339,0,0,0-.521,2.287,7.394,7.394,0,0,0,2.571,1.576,8.626,8.626,0,0,0,6.647-.521c.462-.237,3-1.517,2.761-2.607-.273-1.327-3.187,0-5.925.166C266.571,116.728,263.916,115.33,262.731,115.721Z" transform="translate(48.408 21.388)" fill="#1a1a1a"/>
                <path id="Path_468" data-name="Path 468" d="M267.516,120.725a8.424,8.424,0,0,1-2.844-.5,8.294,8.294,0,0,1-2.773-1.706,1.908,1.908,0,0,1-.592-1.825,1.943,1.943,0,0,1,1.327-1.457h0a6.043,6.043,0,0,1,3.1.379,8.674,8.674,0,0,0,2.536.486,20.142,20.142,0,0,0,2.725-.427c1.837-.367,3.555-.723,3.863.723.213,1.031-.818,2.121-3.081,3.259a9.6,9.6,0,0,1-4.266,1.066Zm-4.195-4.4a.806.806,0,0,0-.308,0h0a.794.794,0,0,0-.545.581.711.711,0,0,0,.225.7,7.109,7.109,0,0,0,2.37,1.457,7.868,7.868,0,0,0,6.174-.5c2.121-1.066,2.488-1.789,2.453-1.955a6.548,6.548,0,0,0-2.465.19,21.211,21.211,0,0,1-2.879.45,9.941,9.941,0,0,1-2.95-.521,9.111,9.111,0,0,0-2.074-.4Z" transform="translate(48.316 21.3)" fill="#1a1a1a"/>
                <path id="Path_469" data-name="Path 469" d="M256.955,371.128H255.77V336.422l3.614-4.752.936.711-3.365,4.443Z" transform="translate(47.3 61.336)" fill="gray"/>
                <circle id="Ellipse_37" data-name="Ellipse 37" cx="7.11" cy="7.11" r="7.11" transform="translate(327.29 258.872)" fill="#662d91"/>
                <path id="Path_470" data-name="Path 470" d="M283.39,233.21a7.56,7.56,0,1,1,7.56-7.56,7.56,7.56,0,0,1-7.56,7.56Zm0-14.219a6.659,6.659,0,1,0,4.717,1.946A6.671,6.671,0,0,0,283.39,218.991Z" transform="translate(51.01 40.332)" fill="gray"/>
                <ellipse id="Ellipse_38" data-name="Ellipse 38" cx="9.479" cy="7.11" rx="9.479" ry="7.11" transform="translate(173.334 240.509) rotate(-63.65)" fill="#206a4a"/>
                <path id="Path_471" data-name="Path 471" d="M154.731,209.456a5.924,5.924,0,0,1-2.654-.6,6.778,6.778,0,0,1-3.555-5.036,11.7,11.7,0,0,1,1.185-7.216c2.429-4.906,7.441-7.394,11.174-5.534a6.73,6.73,0,0,1,3.555,5.036,11.671,11.671,0,0,1-1.185,7.2C161.367,207.11,157.93,209.456,154.731,209.456Zm3.472-18.118c-2.856,0-5.925,2.168-7.7,5.652a10.807,10.807,0,0,0-1.066,6.659,5.842,5.842,0,0,0,3.033,4.4,5.925,5.925,0,0,0,5.344-.249,10.8,10.8,0,0,0,4.633-4.882h0a10.724,10.724,0,0,0,1.078-6.659,5.925,5.925,0,0,0-3.033-4.4,5.131,5.131,0,0,0-2.287-.521Z" transform="translate(27.44 35.22)" fill="gray"/>
                <rect id="Rectangle_124" data-name="Rectangle 124" width="15.404" height="15.404" transform="translate(353.358 216.214)" fill="#f2f2f2"/>
                <path id="Path_472" data-name="Path 472" d="M314.133,198.393H297.84V182.1h16.293Zm-15.4-.889h14.515V182.989H298.729Z" transform="translate(55.08 33.676)" fill="gray"/>
                <rect id="Rectangle_125" data-name="Rectangle 125" width="15.404" height="15.404" transform="translate(250.269 258.872)" fill="#d9e021"/>
                <path id="Path_473" data-name="Path 473" d="M227.133,234.393H210.84V218.1h16.293Zm-15.4-.889h14.515V218.989H211.729Z" transform="translate(38.991 40.334)" fill="gray"/>
                <rect id="Rectangle_126" data-name="Rectangle 126" width="23.699" height="7.358" transform="translate(233.68 224.509)" fill="#1a1a1a"/>
                <path id="Path_474" data-name="Path 474" d="M221.427,197.345H196.84V189.05h24.587Zm-23.7-.889h22.81V190h-22.81Z" transform="translate(36.402 34.961)" fill="gray"/>
                <rect id="Rectangle_127" data-name="Rectangle 127" width="19.089" height="3.555" transform="translate(343.832 247.023)" fill="#f7931e"/>
                <path id="Path_475" data-name="Path 475" d="M309.778,212.544H289.8V208.1h19.978Zm-19.089-.889h18.2v-2.666h-18.2Z" transform="translate(53.593 38.484)" fill="gray"/>
                <ellipse id="Ellipse_39" data-name="Ellipse 39" cx="9.479" cy="7.11" rx="9.479" ry="7.11" transform="matrix(0.618, -0.786, 0.786, 0.618, 199.482, 203.542)" fill="#c69c6d"/>
                <path id="Path_476" data-name="Path 476" d="M177.111,179.7a6.233,6.233,0,0,1-3.9-1.28,6.73,6.73,0,0,1-2.37-5.652,12.252,12.252,0,0,1,8.6-10.961,6.375,6.375,0,0,1,8.472,6.647,11.731,11.731,0,0,1-2.607,6.825h0A10.818,10.818,0,0,1,177.111,179.7Zm4.443-17.3a7.239,7.239,0,0,0-1.908.261,11.363,11.363,0,0,0-7.951,10.131,5.925,5.925,0,0,0,2.062,4.929c2.891,2.263,7.749.924,10.818-3a10.736,10.736,0,0,0,2.37-6.292,5.925,5.925,0,0,0-2.074-4.929A5.391,5.391,0,0,0,181.555,162.4Z" transform="translate(31.591 29.856)" fill="gray"/>
                <ellipse id="Ellipse_40" data-name="Ellipse 40" cx="7.11" cy="9.479" rx="7.11" ry="9.479" transform="translate(386.193 203.618) rotate(-26.35)" fill="#f7931e"/>
                <path id="Path_477" data-name="Path 477" d="M337.829,187.337a7.678,7.678,0,0,1-3.448-.877,11.7,11.7,0,0,1-5.048-5.285c-2.429-4.894-1.363-10.392,2.37-12.24s8.745.64,11.174,5.534a11.7,11.7,0,0,1,1.185,7.216,6.778,6.778,0,0,1-3.555,5.036,5.924,5.924,0,0,1-2.678.616Zm-3.448-18.129a5.214,5.214,0,0,0-2.287.521c-3.294,1.635-4.171,6.588-1.955,11.055a10.735,10.735,0,0,0,4.633,4.882,5.5,5.5,0,0,0,8.378-4.147,11.363,11.363,0,0,0-5.711-11.529,6.766,6.766,0,0,0-3.057-.782Z" transform="translate(60.663 31.128)" fill="gray"/>
                <ellipse id="Ellipse_41" data-name="Ellipse 41" cx="7.11" cy="9.479" rx="7.11" ry="9.479" transform="translate(369.6 185.847) rotate(-26.35)" fill="#29abe2"/>
                <path id="Path_478" data-name="Path 478" d="M323.829,172.337a7.678,7.678,0,0,1-3.448-.877,11.7,11.7,0,0,1-5.048-5.285c-2.429-4.894-1.363-10.392,2.37-12.24s8.745.64,11.174,5.534a11.7,11.7,0,0,1,1.185,7.216,6.778,6.778,0,0,1-3.555,5.036A5.925,5.925,0,0,1,323.829,172.337Zm-3.448-18.129a5.214,5.214,0,0,0-2.287.521c-3.294,1.635-4.171,6.588-1.955,11.055a10.735,10.735,0,0,0,4.633,4.882,5.5,5.5,0,0,0,8.378-4.147,11.364,11.364,0,0,0-5.711-11.529,6.766,6.766,0,0,0-3.057-.782Z" transform="translate(58.074 28.355)" fill="gray"/>
                <rect id="Rectangle_128" data-name="Rectangle 128" width="154.634" height="18.959" transform="translate(224.793 328.19)" fill="#c69e52"/>
                <path id="Path_479" data-name="Path 479" d="M345.029,296.614H189.21V276.47H345.029Zm-154.634-1.185H343.844V277.655H190.395Z" transform="translate(34.991 51.128)" fill="gray"/>
                <rect id="Rectangle_129" data-name="Rectangle 129" width="31.993" height="31.993" transform="translate(285.521 321.673)" fill="#999"/>
                <path id="Path_480" data-name="Path 480" d="M273.638,304.178H240.46V271h33.178Zm-31.993-1.185h30.808V272.185H241.645Z" transform="translate(44.469 50.116)" fill="gray"/>
                <rect id="Rectangle_130" data-name="Rectangle 130" width="18.959" height="18.959" transform="translate(292.038 328.19)" fill="#c69e52"/>
                <path id="Path_481" data-name="Path 481" d="M266.144,296.614H246V276.47h20.144Zm-18.959-1.185h17.774V277.655H247.185Z" transform="translate(45.493 51.128)" fill="gray"/>
                <circle id="Ellipse_42" data-name="Ellipse 42" cx="7.11" cy="7.11" r="7.11" transform="translate(322.55 330.56)" fill="#999"/>
                <path id="Path_482" data-name="Path 482" d="M279.412,293.874a7.7,7.7,0,1,1,7.7-7.7,7.7,7.7,0,0,1-7.7,7.7Zm0-14.219a6.517,6.517,0,1,0,6.517,6.517,6.517,6.517,0,0,0-6.517-6.517Z" transform="translate(50.248 51.498)" fill="gray"/>
                <path id="Path_483" data-name="Path 483" d="M312.71,189.47" transform="translate(57.83 35.039)" fill="none" stroke="gray" stroke-miterlimit="10" stroke-width="1"/>
                <rect id="Rectangle_131" data-name="Rectangle 131" width="19.089" height="3.555" transform="translate(343.832 239.084)" fill="#f7931e"/>
                <path id="Path_484" data-name="Path 484" d="M309.778,205.833H289.8V201.39h19.978Zm-19.089-.889h18.2v-2.666h-18.2Z" transform="translate(53.593 37.243)" fill="gray"/>
                <rect id="Rectangle_132" data-name="Rectangle 132" width="225.978" height="1.185" transform="translate(24.884 265.982)" fill="gray"/>
                <path id="Path_485" data-name="Path 485" d="M522.846,233.6H350.225L305.98,212.086l.521-1.066,44,21.4H522.846Z" transform="translate(56.585 39.024)" fill="gray"/>
                <path id="Path_486" data-name="Path 486" d="M317.985,169.285H316.8V72.5H520.845v1.185H317.985Z" transform="translate(58.586 13.408)" fill="gray"/>
                <path id="Path_487" data-name="Path 487" d="M335.535,177.749H334.35V124.64H517.6v1.185H335.535Z" transform="translate(61.832 23.05)" fill="gray"/>
                <path id="Path_488" data-name="Path 488" d="M306.659,205.745l-.509-1.066,44.2-21.518H522.815v1.185H350.62Z" transform="translate(56.617 33.872)" fill="gray"/>
                <path id="Path_489" data-name="Path 489" d="M526.179,289.721H400.173l-.142-.083L287.96,225.059l.592-1.019,111.94,64.5H526.179Z" transform="translate(53.253 41.432)" fill="gray"/>
                <path id="Path_490" data-name="Path 490" d="M198.611,178.741h-1.185V73.685H57V72.5H198.611Z" transform="translate(10.541 13.408)" fill="gray"/>
                <path id="Path_491" data-name="Path 491" d="M180.918,203.237h-1.185V125.825H21V124.64H180.918Z" transform="translate(3.884 23.05)" fill="gray"/>
                <path id="Path_492" data-name="Path 492" d="M223.732,218.373h-1.185V34.365H57V33.18H223.732Z" transform="translate(10.541 6.136)" fill="gray"/>
                <path id="Path_493" data-name="Path 493" d="M307.5,210.019H306.32V33.5H454.057v1.185H307.5Z" transform="translate(56.648 6.195)" fill="gray"/>
                <path id="Path_494" data-name="Path 494" d="M526.928,354.213H371.607l-.154-.118L283.91,286.518l.723-.948,87.377,67.458H526.928Z" transform="translate(52.504 52.811)" fill="gray"/>
                <path id="Path_495" data-name="Path 495" d="M57.29,23.45h3.744a4.74,4.74,0,0,1,0,9.479H57.29Zm3.744,7.761a2.832,2.832,0,0,0,3.022-3.01,2.773,2.773,0,0,0-3.022-2.915H59.316v5.925Z" transform="translate(10.595 4.337)" fill="#fff"/>
                <path id="Path_496" data-name="Path 496" d="M71.7,31.294H67.435l-.592,1.635H64.65l3.661-9.479h2.536l3.65,9.479h-2.2ZM68,29.552h3.14L69.579,25.2Z" transform="translate(11.956 4.337)" fill="#fff"/>
                <path id="Path_497" data-name="Path 497" d="M75.724,26.566v6.363H73.71V23.45h2.074l4.4,6.138V23.45h2.014v9.479H80.239Z" transform="translate(13.631 4.337)" fill="#fff"/>
                <path id="Path_498" data-name="Path 498" d="M90.7,26.1l-2.666,6.825H87.14L84.474,26.1v6.825H82.46V23.45h2.82l2.37,5.925,2.3-5.925h2.844v9.479H90.7Z" transform="translate(15.249 4.337)" fill="#fff"/>
                <path id="Path_499" data-name="Path 499" d="M98.91,31.294H94.68l-.6,1.635H91.86l3.673-9.479h2.524l3.661,9.479h-2.2Zm-3.7-1.742h3.14L96.766,25.2Z" transform="translate(16.988 4.337)" fill="#fff"/>
                <path id="Path_500" data-name="Path 500" d="M104.416,29.53h-1.469v3.4H100.92V23.451h4.443a2.95,2.95,0,0,1,3.176,3.022,2.713,2.713,0,0,1-2.074,2.808L108.6,32.9h-2.37Zm.64-4.349h-2.109V27.8h2.109a1.315,1.315,0,1,0,0-2.619Z" transform="translate(18.663 4.335)" fill="#fff"/>
                <path id="Path_501" data-name="Path 501" d="M111.431,29.141l-.747.877v2.915H108.67V23.454h2.014V27.66l3.353-4.23h2.488l-3.768,4.455,4.029,5.024H114.3Z" transform="translate(20.097 4.333)" fill="#fff"/>
                <path id="Path_502" data-name="Path 502" d="M116.852,30.051a4.112,4.112,0,0,0,3.01,1.28c1.1,0,1.647-.533,1.647-1.066,0-.711-.829-.96-1.92-1.185-1.552-.355-3.555-.782-3.555-2.9,0-1.576,1.363-2.856,3.555-2.856a5.261,5.261,0,0,1,3.7,1.327L122.1,26.117a4.041,4.041,0,0,0-2.714-1.031c-.912,0-1.4.391-1.4.96s.794.841,1.9,1.1c1.564.355,3.555.818,3.555,2.927,0,1.73-1.185,3.033-3.8,3.033a5.427,5.427,0,0,1-4.017-1.5Z" transform="translate(21.382 4.312)" fill="#fff"/>
                <path id="Path_503" data-name="Path 503" d="M131.717,26.1l-2.678,6.825h-.877L125.484,26.1v6.825H123.47V23.45H126.3l2.3,5.925,2.3-5.925h2.844v9.479h-2.026Z" transform="translate(22.833 4.337)" fill="#fff"/>
                <path id="Path_504" data-name="Path 504" d="M139.406,31.294h-3.4l-.96,1.635H132.83L138.6,23.45h7.465v1.73h-4.621v2.062h4.621v1.742h-4.621v2.228h4.621v1.742h-6.707Zm-2.465-1.742h2.465V25.18Z" transform="translate(24.564 4.337)" fill="#fff"/>
                <path id="Path_505" data-name="Path 505" d="M148.846,29.53h-1.481v3.4H145.35V23.452h4.432a2.95,2.95,0,0,1,3.187,3.022,2.714,2.714,0,0,1-2.109,2.844l2.133,3.614h-2.311Zm.64-4.349h-2.121V27.8h2.121a1.315,1.315,0,1,0,0-2.619Z" transform="translate(26.88 4.335)" fill="#fff"/>
                <path id="Path_506" data-name="Path 506" d="M155.821,29.141l-.735.877v2.915H153.06V23.454h2.026V27.66l3.353-4.23h2.488l-3.768,4.455,4.017,5.024h-2.488Z" transform="translate(28.306 4.333)" fill="#fff"/>
                <path id="Path_507" data-name="Path 507" d="M160.7,23.45h6.707v1.73h-4.74v2.062h4.586v1.742h-4.538v2.228h4.74v1.742H160.7Z" transform="translate(29.718 4.337)" fill="#fff"/>
                <path id="Path_508" data-name="Path 508" d="M57.44,62.25h2.3l2.631,7.287,2.619-7.287h2.3l-3.65,9.479H61.1Z" transform="translate(10.622 11.512)" fill="#fff"/>
                <path id="Path_509" data-name="Path 509" d="M72.095,70.1H67.9l-.545,1.623H65.08l3.673-9.479h2.524l3.661,9.479h-2.2ZM68.41,68.342h3.14l-1.564-4.349Z" transform="translate(12.035 11.51)" fill="#fff"/>
                <path id="Path_510" data-name="Path 510" d="M76.166,65.356v6.363H74.14V62.24h2.074l4.4,6.138V62.252h2.014v9.479H80.681Z" transform="translate(13.711 11.51)" fill="#fff"/>
                <path id="Path_511" data-name="Path 511" d="M82.89,62.25h3.744a4.74,4.74,0,0,1,0,9.479H82.89Zm3.744,7.7A2.832,2.832,0,0,0,89.6,66.99a2.773,2.773,0,0,0-2.962-2.974H84.9V69.94Z" transform="translate(15.329 11.512)" fill="#fff"/>
                <path id="Path_512" data-name="Path 512" d="M95.006,68.332H93.524v3.389H91.51V62.242h4.432a2.95,2.95,0,0,1,3.187,3.045,2.737,2.737,0,0,1-2.074,2.82l2.121,3.555H96.83Zm.64-4.349H93.524V66.6h2.121a1.315,1.315,0,1,0,0-2.619Z" transform="translate(16.923 11.509)" fill="#fff"/>
                <path id="Path_513" data-name="Path 513" d="M99.22,62.25h6.707v1.73h-4.68v2.062h4.586v1.73h-4.586v2.2h4.68v1.742H99.22Z" transform="translate(18.349 11.512)" fill="#fff"/>
                <path id="Path_514" data-name="Path 514" d="M114.467,64.906l-2.678,6.813h-.877l-2.678-6.813v6.813H106.22V62.24h2.832l2.3,5.925,2.3-5.925h2.844v9.479h-2.026Z" transform="translate(19.643 11.51)" fill="#fff"/>
                <path id="Path_515" data-name="Path 515" d="M122.156,70.1h-3.413l-.948,1.623H115.58l5.771-9.479h7.512v1.73h-4.669v2.062h4.574v1.73h-4.574v2.2h4.669v1.742h-6.707Zm-2.465-1.754h2.465V63.982Z" transform="translate(21.374 11.51)" fill="#fff"/>
                <path id="Path_516" data-name="Path 516" d="M131.6,68.332h-1.481v3.389H128.1V62.241h4.432a2.95,2.95,0,0,1,3.176,3.045,2.725,2.725,0,0,1-2.074,2.82l2.133,3.555h-2.311Zm.64-4.349h-2.121V66.6h2.121a1.315,1.315,0,1,0,0-2.619Z" transform="translate(23.69 11.509)" fill="#fff"/>
                <path id="Path_517" data-name="Path 517" d="M138.571,67.888l-.747.889v2.9H135.81V62.2h2.014v4.23l3.353-4.23h2.488l-3.756,4.5,4.017,5.024h-2.488Z" transform="translate(25.116 11.503)" fill="#fff"/>
                <path id="Path_518" data-name="Path 518" d="M143.444,62.25h6.707v1.73h-4.68v2.062h4.562v1.73h-4.586v2.2h4.68v1.742H143.42Z" transform="translate(26.523 11.512)" fill="#fff"/>
                <path id="Path_519" data-name="Path 519" d="M27.99,108.094H23.748l-.592,1.635H20.94L24.6,100.25h2.536l3.65,9.479h-2.2Zm-3.7-1.754h3.14l-1.564-4.349Z" transform="translate(3.872 18.539)" fill="#fff"/>
                <path id="Path_520" data-name="Path 520" d="M32.761,105.886l-.747.889v2.915H30V100.21h2.014v4.23l3.353-4.23h2.488L34.088,104.7l4.029,5.036H35.628Z" transform="translate(5.548 18.532)" fill="#fff"/>
                <path id="Path_521" data-name="Path 521" d="M39.913,102.016H37.14V100.25H44.7v1.766H41.939v7.714H39.913Z" transform="translate(6.868 18.539)" fill="#fff"/>
                <path id="Path_522" data-name="Path 522" d="M44.61,100.25h2.026v9.479H44.61Z" transform="translate(8.25 18.539)" fill="#fff"/>
                <path id="Path_523" data-name="Path 523" d="M47.05,100.25h2.311l2.631,7.287,2.619-7.287h2.3l-3.65,9.479H50.723Z" transform="translate(8.701 18.539)" fill="#fff"/>
                <path id="Path_524" data-name="Path 524" d="M56.11,100.25h2.014v9.479H56.11Z" transform="translate(10.377 18.539)" fill="#fff"/>
                <path id="Path_525" data-name="Path 525" d="M61.683,102.016H58.91V100.25h7.56v1.766H63.756v7.714H61.683Z" transform="translate(10.894 18.539)" fill="#fff"/>
                <path id="Path_526" data-name="Path 526" d="M66.38,100.25h6.659v1.73H68.3v2.062h4.74v1.73H68.406v2.2h4.74v1.754H66.38Z" transform="translate(12.276 18.539)" fill="#fff"/>
                <path id="Path_527" data-name="Path 527" d="M75.663,102.016H72.89V100.25h7.56v1.766H77.689v7.714H75.663Z" transform="translate(13.48 18.539)" fill="#fff"/>
                <path id="Path_528" data-name="Path 528" d="M80.849,106.869a4.159,4.159,0,0,0,3.01,1.28c1.114,0,1.659-.521,1.659-1.066s-.829-.948-1.92-1.185c-1.552-.355-3.555-.782-3.555-2.891,0-1.576,1.363-2.856,3.555-2.856a5.261,5.261,0,0,1,3.7,1.315l-1.185,1.481A4.029,4.029,0,0,0,83.4,101.9c-.9,0-1.386.4-1.386.972s.794.841,1.884,1.09c1.564.355,3.555.829,3.555,2.927,0,1.742-1.185,3.045-3.792,3.045a5.451,5.451,0,0,1-4.017-1.5Z" transform="translate(14.728 18.52)" fill="#fff"/>
                <path id="Path_529" data-name="Path 529" d="M87.05,104.7h3.4v1.54h-3.4Z" transform="translate(16.098 19.362)" fill="#fff"/>
                <path id="Path_530" data-name="Path 530" d="M98.712,100.111a4.906,4.906,0,1,1-4.941,4.906,4.74,4.74,0,0,1,4.941-4.906Zm0,1.789a3.128,3.128,0,1,0,2.879,3.116,2.868,2.868,0,0,0-2.879-3.116Z" transform="translate(17.341 18.513)" fill="#fff"/>
                <path id="Path_531" data-name="Path 531" d="M108.005,100.113a4.444,4.444,0,0,1,3.922,2.074l-1.671.912a2.714,2.714,0,0,0-2.251-1.185,3.116,3.116,0,1,0,0,6.233,3.211,3.211,0,0,0,2.05-.723v-1.185h-2.524v-1.73h4.515v3.626a5.285,5.285,0,0,1-4.041,1.789,4.917,4.917,0,1,1,0-9.823Z" transform="translate(19.02 18.511)" fill="#fff"/>
                <path id="Path_532" data-name="Path 532" d="M22.363,121.269a4.112,4.112,0,0,0,3.01,1.28c1.1,0,1.647-.521,1.647-1.066s-.829-.948-1.92-1.185c-1.552-.355-3.555-.782-3.555-2.891,0-1.588,1.363-2.856,3.555-2.856a5.3,5.3,0,0,1,3.7,1.315l-1.126,1.481a4.052,4.052,0,0,0-2.713-1.043c-.912,0-1.4.4-1.4.972s.794.841,1.9,1.09c1.564.355,3.555.829,3.555,2.927,0,1.742-1.244,3.045-3.8,3.045a5.474,5.474,0,0,1-4.017-1.5Z" transform="translate(3.919 21.183)" fill="#fff"/>
                <path id="Path_533" data-name="Path 533" d="M29,114.65h2.014v9.479H29Z" transform="translate(5.363 21.202)" fill="#fff"/>
                <path id="Path_534" data-name="Path 534" d="M35.041,120.326l-.747.889v2.915H32.28V114.65h2.038v4.23l3.353-4.23H40.16l-3.768,4.443,4.029,5.036H37.873Z" transform="translate(5.97 21.202)" fill="#fff"/>
                <path id="Path_535" data-name="Path 535" d="M42.671,120.326l-.747.889v2.915H39.91V114.65h2.014v4.23l3.353-4.23h2.488l-3.756,4.443,4.017,5.036H45.538Z" transform="translate(7.381 21.202)" fill="#fff"/>
                <path id="Path_536" data-name="Path 536" d="M47.54,114.65h6.707v1.73h-4.74v2.062h4.586v1.73H49.566v2.2h4.74v1.754H47.54Z" transform="translate(8.792 21.202)" fill="#fff"/>
                <path id="Path_537" data-name="Path 537" d="M58.036,120.731H56.554v3.4H54.54v-9.479h4.432a2.95,2.95,0,0,1,3.223,3.045,2.725,2.725,0,0,1-2.074,2.82l2.133,3.614h-2.37Zm.64-4.349H56.554V119H58.64a1.315,1.315,0,1,0,0-2.619Z" transform="translate(10.086 21.2)" fill="#fff"/>
                <path id="Path_538" data-name="Path 538" d="M68.746,120.148H64.314v3.981H62.3V114.65h2.014v3.721h4.479V114.65H70.82v9.479H68.746Z" transform="translate(11.521 21.202)" fill="#fff"/>
                <path id="Path_539" data-name="Path 539" d="M71,114.65h6.707v1.73h-4.74v2.062h4.669v1.73H73.05v2.2h4.74v1.754H71Z" transform="translate(13.13 21.202)" fill="#fff"/>
                <path id="Path_540" data-name="Path 540" d="M78,114.65h3.744a4.74,4.74,0,1,1,0,9.479H78Zm3.744,7.7a2.832,2.832,0,0,0,2.962-2.962,2.773,2.773,0,0,0-2.962-2.974H80.05v5.925Z" transform="translate(14.425 21.202)" fill="#fff"/>
                <path id="Path_541" data-name="Path 541" d="M87.179,121.269a4.159,4.159,0,0,0,3.01,1.28c1.114,0,1.659-.521,1.659-1.066s-.829-.948-1.92-1.185c-1.552-.355-3.555-.782-3.555-2.891,0-1.588,1.363-2.856,3.555-2.856a5.249,5.249,0,0,1,3.685,1.315L92.5,117.347a4.076,4.076,0,0,0-2.714-1.043c-.912,0-1.4.4-1.4.972s.794.841,1.9,1.09c1.552.355,3.555.829,3.555,2.927,0,1.742-1.185,3.045-3.792,3.045a5.474,5.474,0,0,1-4.017-1.5Z" transform="translate(15.91 21.183)" fill="#fff"/>
                <path id="Path_542" data-name="Path 542" d="M102.087,117.3l-2.678,6.825h-.877L95.854,117.3v6.825H93.84V114.65h2.832l2.3,5.925,2.3-5.925h2.844v9.479h-2.026Z" transform="translate(17.354 21.202)" fill="#fff"/>
                <path id="Path_543" data-name="Path 543" d="M109.776,122.494h-3.413l-.948,1.635H103.2l5.771-9.479h7.512v1.73h-4.669v2.062h4.574v1.73h-4.574v2.2h4.669v1.754h-6.707Zm-2.465-1.754h2.465V116.38Z" transform="translate(19.085 21.202)" fill="#fff"/>
                <path id="Path_544" data-name="Path 544" d="M119.216,120.73h-1.481v3.4H115.72v-9.479h4.432a2.95,2.95,0,0,1,3.176,3.045,2.714,2.714,0,0,1-2.074,2.82l2.133,3.614h-2.37Zm.628-4.349h-2.109V119h2.109a1.315,1.315,0,1,0,0-2.619Z" transform="translate(21.4 21.201)" fill="#fff"/>
                <path id="Path_545" data-name="Path 545" d="M126.191,120.326l-.746.889v2.915H123.43V114.65h2.014v4.23l3.353-4.23h2.488l-3.756,4.443,4.017,5.036h-2.488Z" transform="translate(22.826 21.202)" fill="#fff"/>
                <path id="Path_546" data-name="Path 546" d="M131.06,114.65h6.707v1.73h-4.68v2.062h4.586v1.73h-4.586v2.2h4.68v1.754H131.06Z" transform="translate(24.237 21.202)" fill="#fff"/>
                <path id="Path_547" data-name="Path 547" d="M21.79,214.25h3.8a4.74,4.74,0,0,1,0,9.479h-3.8Zm3.744,7.7A2.832,2.832,0,0,0,28.5,218.99a2.773,2.773,0,0,0-2.962-2.974H23.8v5.925Z" transform="translate(4.03 39.622)" fill="#fff"/>
                <path id="Path_548" data-name="Path 548" d="M30.41,214.25h2.014v9.479H30.41Z" transform="translate(5.624 39.622)" fill="#fff"/>
                <path id="Path_549" data-name="Path 549" d="M32.84,214.25h2.311l2.619,7.287,2.607-7.287h2.311l-3.661,9.479H36.513Z" transform="translate(6.073 39.622)" fill="#fff"/>
                <path id="Path_550" data-name="Path 550" d="M41.9,214.25h2.026v9.479H41.9Z" transform="translate(7.749 39.622)" fill="#fff"/>
                <path id="Path_551" data-name="Path 551" d="M45.772,220.871a4.112,4.112,0,0,0,3.01,1.28c1.1,0,1.647-.521,1.647-1.066s-.829-.948-1.92-1.185c-1.552-.355-3.555-.782-3.555-2.891,0-1.588,1.375-2.868,3.555-2.868a5.285,5.285,0,0,1,3.7,1.327l-1.185,1.481a4.052,4.052,0,0,0-2.713-1.043c-.912,0-1.4.4-1.4.972s.794.841,1.9,1.09c1.564.355,3.555.829,3.555,2.927,0,1.742-1.244,3.045-3.8,3.045a5.5,5.5,0,0,1-4.017-1.5Z" transform="translate(8.237 39.601)" fill="#fff"/>
                <path id="Path_552" data-name="Path 552" d="M52.39,214.25h2.026v9.479H52.39Z" transform="translate(9.689 39.622)" fill="#fff"/>
                <path id="Path_553" data-name="Path 553" d="M60.242,214.111a4.906,4.906,0,1,1-4.941,4.906,4.74,4.74,0,0,1,4.941-4.906Zm0,1.789a3.128,3.128,0,1,0,2.891,3.116,2.868,2.868,0,0,0-2.891-3.116Z" transform="translate(10.226 39.595)" fill="#fff"/>
                <path id="Path_554" data-name="Path 554" d="M66.874,217.356v6.363H64.86V214.24h2.074l4.4,6.138V214.24H73.32v9.479H71.4Z" transform="translate(11.995 39.62)" fill="#fff"/>
                <path id="Path_555" data-name="Path 555" d="M74.179,220.871a4.159,4.159,0,0,0,3.01,1.28c1.114,0,1.659-.521,1.659-1.066s-.829-.948-1.92-1.185c-1.552-.355-3.555-.782-3.555-2.891,0-1.588,1.363-2.868,3.555-2.868a5.273,5.273,0,0,1,3.7,1.327l-1.114,1.481a4.076,4.076,0,0,0-2.725-1.043c-.9,0-1.386.4-1.386.972s.794.841,1.884,1.09c1.564.355,3.555.829,3.555,2.927,0,1.742-1.185,3.045-3.792,3.045a5.474,5.474,0,0,1-4.017-1.5Z" transform="translate(13.506 39.601)" fill="#fff"/>
                <path id="Path_556" data-name="Path 556" d="M89.045,216.906l-2.666,6.813H85.5l-2.678-6.813v6.813H80.81V214.24h2.832l2.3,5.925,2.3-5.925h2.844v9.479H89.045Z" transform="translate(14.944 39.62)" fill="#fff"/>
                <path id="Path_557" data-name="Path 557" d="M96.746,222.1H93.334l-.948,1.623H90.17l5.771-9.479h7.512v1.73H98.784v2.062h4.574v1.73H98.784v2.2h4.669v1.742H96.746Zm-2.477-1.754h2.477v-4.361Z" transform="translate(16.675 39.62)" fill="#fff"/>
                <path id="Path_558" data-name="Path 558" d="M106.176,220.332h-1.469v3.389H102.68v-9.479h4.443a2.95,2.95,0,0,1,3.176,3.045,2.737,2.737,0,0,1-2.074,2.82l2.133,3.555h-2.37Zm.64-4.349h-2.109V218.6h2.109a1.315,1.315,0,1,0,0-2.619Z" transform="translate(18.989 39.618)" fill="#fff"/>
                <path id="Path_559" data-name="Path 559" d="M113.161,219.888l-.747.889v2.9H110.4V214.2h2.014v4.23l3.353-4.23h2.453l-3.733,4.5,4.029,5.024h-2.488Z" transform="translate(20.416 39.612)" fill="#fff"/>
                <path id="Path_560" data-name="Path 560" d="M118,214.25h6.707v1.73h-4.74v2.062h4.6v1.73h-4.6v2.2h4.74v1.742H118Z" transform="translate(21.822 39.622)" fill="#fff"/>
                <path id="Path_561" data-name="Path 561" d="M351.641,29.058l-.735.877v2.915H348.88V23.37h2.026v4.3l3.353-4.242h2.488l-3.768,4.455L357,32.909h-2.488Z" transform="translate(64.519 4.322)" fill="#fff"/>
                <path id="Path_562" data-name="Path 562" d="M360.6,23.241a4.906,4.906,0,1,1-4.953,4.906,4.74,4.74,0,0,1,4.953-4.906Zm0,1.789a2.868,2.868,0,0,0-2.879,3.116,2.891,2.891,0,1,0,5.759,0A2.879,2.879,0,0,0,360.6,25.03Z" transform="translate(65.77 4.297)" fill="#fff"/>
                <path id="Path_563" data-name="Path 563" d="M368.716,29.462h-1.481V32.85H365.22V23.371H369.7a2.939,2.939,0,0,1,3.128,3.116,2.725,2.725,0,0,1-2.074,2.82l2.133,3.555h-2.311Zm.64-4.349h-2.121v2.559h2.121a1.3,1.3,0,0,0,0-2.607Z" transform="translate(67.541 4.321)" fill="#fff"/>
                <path id="Path_564" data-name="Path 564" d="M372.93,23.37h4.432a3.045,3.045,0,1,1,0,6.091h-2.37v3.389h-2.026Zm4.171,1.742h-2.145v2.559H377.1a1.3,1.3,0,0,0,0-2.607Z" transform="translate(68.967 4.322)" fill="#fff"/>
                <path id="Path_565" data-name="Path 565" d="M380.989,30.066a4.171,4.171,0,0,0,3.022,1.185c1.1,0,1.647-.521,1.647-1.066s-.829-.948-1.92-1.185c-1.552-.355-3.555-.782-3.555-2.9,0-1.576,1.363-2.856,3.555-2.856a5.308,5.308,0,0,1,3.7,1.327l-1.185,1.481a4.053,4.053,0,0,0-2.714-1.043c-.912,0-1.4.4-1.4.972s.794.829,1.9,1.09c1.564.355,3.555.818,3.555,2.927,0,1.73-1.185,3.045-3.792,3.045a5.475,5.475,0,0,1-4.017-1.5Z" transform="translate(70.233 4.297)" fill="#fff"/>
                <path id="Path_566" data-name="Path 566" d="M395.867,26.036l-2.678,6.813h-.877l-2.678-6.813v6.813H387.62V23.37h2.82l2.3,5.925,2.3-5.925h2.844v9.479h-2.026Z" transform="translate(71.683 4.322)" fill="#fff"/>
                <path id="Path_567" data-name="Path 567" d="M403.553,31.224h-3.4l-.96,1.635H397l5.771-9.479h7.512v1.742h-4.669v2.062h4.574v1.671h-4.574v2.251h4.669v1.742h-6.707Zm-2.465-1.742h2.465V25.11Z" transform="translate(73.418 4.324)" fill="#fff"/>
                <path id="Path_568" data-name="Path 568" d="M413,29.462h-1.481v3.389H409.5V23.372h4.432a2.939,2.939,0,0,1,3.176,3.116,2.725,2.725,0,0,1-2.074,2.82l2.133,3.555H414.8Zm.64-4.349h-2.121v2.559h2.133a1.3,1.3,0,1,0,0-2.607Z" transform="translate(75.729 4.32)" fill="#fff"/>
                <path id="Path_569" data-name="Path 569" d="M419.971,29.058l-.735.877v2.915H417.21V23.37h2.026v4.3l3.353-4.242h2.477l-3.756,4.455,4.017,5.024h-2.441Z" transform="translate(77.155 4.322)" fill="#fff"/>
                <path id="Path_570" data-name="Path 570" d="M424.84,23.37h6.707v1.742h-4.68v2.062h4.586v1.683h-4.586v2.251h4.68v1.742H424.84Z" transform="translate(78.566 4.322)" fill="#fff"/>
                <path id="Path_571" data-name="Path 571" d="M335.312,69.511a4.135,4.135,0,0,0,3.01,1.28c1.1,0,1.647-.521,1.647-1.066s-.818-.948-1.92-1.185c-1.552-.355-3.555-.782-3.555-2.9,0-1.576,1.363-2.856,3.555-2.856a5.285,5.285,0,0,1,3.7,1.327l-1.185,1.481a4.052,4.052,0,0,0-2.714-1.043c-.912,0-1.4.4-1.4.972s.806.829,1.9,1.09c1.564.355,3.555.829,3.555,2.927,0,1.73-1.185,3.045-3.792,3.045a5.51,5.51,0,0,1-4.029-1.5Z" transform="translate(61.782 11.609)" fill="#fff"/>
                <path id="Path_572" data-name="Path 572" d="M341.93,62.88h4.432a3.045,3.045,0,1,1,0,6.091h-2.37v3.389h-2.026Zm4.171,1.742h-2.145v2.607H346.1a1.3,1.3,0,0,0,0-2.607Z" transform="translate(63.234 11.628)" fill="#fff"/>
                <path id="Path_573" data-name="Path 573" d="M349.42,62.88h6.707v1.742h-4.681v2.062h4.586v1.73h-4.586v2.2h4.681v1.742H349.42Z" transform="translate(64.619 11.628)" fill="#fff"/>
                <path id="Path_574" data-name="Path 574" d="M356,67.656a4.8,4.8,0,0,1,4.977-4.906,4.384,4.384,0,0,1,4.041,2.37l-1.742.853a2.583,2.583,0,0,0-2.3-1.386,3.128,3.128,0,0,0,0,6.233,2.559,2.559,0,0,0,2.3-1.386l1.742.841a4.408,4.408,0,0,1-4.041,2.37A4.8,4.8,0,0,1,356,67.656Z" transform="translate(65.835 11.604)" fill="#fff"/>
                <path id="Path_575" data-name="Path 575" d="M364.66,62.88h2.026v9.479H364.66Z" transform="translate(67.437 11.628)" fill="#fff"/>
                <path id="Path_576" data-name="Path 576" d="M368,62.88h6.707v1.742h-4.74v2.062h4.6v1.73h-4.6v2.2h4.74v1.742H368Z" transform="translate(68.055 11.628)" fill="#fff"/>
                <path id="Path_577" data-name="Path 577" d="M375,62.88h2.014v7.7h3.91v1.777H375Z" transform="translate(69.349 11.628)" fill="#fff"/>
                <path id="Path_578" data-name="Path 578" d="M382.3,64.657H379.53V62.88h7.56v1.777h-2.761v7.7H382.3Z" transform="translate(70.187 11.628)" fill="#fff"/>
                <path id="Path_579" data-name="Path 579" d="M398.287,65.546l-2.666,6.813h-.889l-2.678-6.813v6.813H390.04V62.88h2.82l2.37,5.925,2.3-5.925h2.844v9.479h-2.085Z" transform="translate(72.131 11.628)" fill="#fff"/>
                <path id="Path_580" data-name="Path 580" d="M406.026,70.724h-3.413l-.948,1.635H399.45l5.771-9.479h7.512v1.742h-4.669v2.062h4.574v1.73h-4.574v2.2h4.669v1.742h-6.707Zm-2.488-1.777h2.488V64.622Z" transform="translate(73.871 11.628)" fill="#fff"/>
                <path id="Path_581" data-name="Path 581" d="M415.436,68.952h-1.469v3.389H412V62.861h4.444a2.951,2.951,0,0,1,3.176,3.057,2.737,2.737,0,0,1-2.074,2.82l2.133,3.555h-2.311Zm.64-4.349h-2.109V67.21h2.109a1.3,1.3,0,1,0,0-2.607Z" transform="translate(76.192 11.623)" fill="#fff"/>
                <path id="Path_582" data-name="Path 582" d="M422.431,68.568l-.747.877v2.915H419.67V62.88h2.014v4.242l3.353-4.242h2.488l-3.768,4.455,4.029,5.024H425.3Z" transform="translate(77.61 11.628)" fill="#fff"/>
                <path id="Path_583" data-name="Path 583" d="M427.3,62.88h6.754v1.742h-4.74v2.062h4.6v1.73h-4.6v2.2h4.74v1.742H427.3Z" transform="translate(79.021 11.628)" fill="#fff"/>
                <path id="Path_584" data-name="Path 584" d="M437.37,62.88h6.671v1.742H439.3v2.062h4.74v1.73h-4.6v3.946h-2.014Z" transform="translate(80.883 11.628)" fill="#fff"/>
                <path id="Path_585" data-name="Path 585" d="M447.716,68.952h-1.481v3.389H444.22V62.862H448.7a2.95,2.95,0,0,1,3.188,3.057,2.737,2.737,0,0,1-2.074,2.82l2.133,3.555h-2.37Zm.64-4.349h-2.121V67.21h2.121a1.3,1.3,0,0,0,0-2.607Z" transform="translate(82.15 11.623)" fill="#fff"/>
                <path id="Path_586" data-name="Path 586" d="M458.1,70.724h-4.23l-.592,1.635H451.06l3.661-9.479h2.524l3.661,9.479h-2.2Zm-3.65-1.777h3.14L456.025,64.6Z" transform="translate(83.415 11.628)" fill="#fff"/>
                <path id="Path_587" data-name="Path 587" d="M463.22,62.88h2.014v7.7h4.005v1.777H463.22Z" transform="translate(85.664 11.628)" fill="#fff"/>
                <path id="Path_588" data-name="Path 588" d="M469.49,62.88H476.2v1.742h-4.68v2.062H476.1v1.73h-4.586v2.2H476.2v1.742H469.49Z" transform="translate(86.823 11.628)" fill="#fff"/>
                <path id="Path_589" data-name="Path 589" d="M476.647,70.061a2.168,2.168,0,0,0,1.517.687,1.41,1.41,0,0,0,1.434-1.529V62.88h2.026v6.375a3.021,3.021,0,0,1-3.306,3.247,3.555,3.555,0,0,1-2.548-.924Z" transform="translate(87.985 11.628)" fill="#fff"/>
                <path id="Path_590" data-name="Path 590" d="M485.821,68.952h-1.5v3.389H482.29V62.862h4.432a2.95,2.95,0,0,1,3.187,3.057,2.737,2.737,0,0,1-2.038,2.844L490,72.317h-2.37Zm.64-4.349h-2.145V67.21h2.109a1.3,1.3,0,0,0,0-2.607Z" transform="translate(89.191 11.623)" fill="#fff"/>
                <path id="Path_591" data-name="Path 591" d="M369.609,121.151a4.159,4.159,0,0,0,3.01,1.28c1.114,0,1.659-.533,1.659-1.066s-.829-.948-1.92-1.185c-1.552-.355-3.555-.782-3.555-2.9,0-1.576,1.363-2.856,3.555-2.856a5.273,5.273,0,0,1,3.7,1.327l-1.114,1.469a4.124,4.124,0,0,0-2.725-1.031c-.9,0-1.386.4-1.386.96s.794.841,1.884,1.1c1.564.355,3.555.818,3.555,2.927,0,1.73-1.185,3.045-3.792,3.045a5.427,5.427,0,0,1-4.017-1.517Z" transform="translate(68.14 21.159)" fill="#fff"/>
                <path id="Path_592" data-name="Path 592" d="M381.848,122.364h-3.413L377.488,124H375.26l5.771-9.479h7.524v1.754h-4.74v2.062h4.574v1.742h-4.574v2.2h4.74v1.742h-6.707Zm-2.477-1.742h2.477v-4.349Z" transform="translate(69.397 21.178)" fill="#fff"/>
                <path id="Path_593" data-name="Path 593" d="M391.276,120.612h-1.481V124H387.78v-9.479h4.432a2.95,2.95,0,0,1,3.187,3.057,2.737,2.737,0,0,1-2.074,2.82l2.133,3.555h-2.37Zm.64-4.361h-2.121v2.619h2.121a1.315,1.315,0,1,0,0-2.619Z" transform="translate(71.713 21.177)" fill="#fff"/>
                <path id="Path_594" data-name="Path 594" d="M395.5,114.52h4.432a3.045,3.045,0,1,1,0,6.091h-2.37V124h-2.014Zm4.147,1.754h-2.133v2.619h2.133a1.292,1.292,0,0,0,1.4-1.3,1.315,1.315,0,0,0-1.4-1.315Z" transform="translate(73.14 21.178)" fill="#fff"/>
                <path id="Path_595" data-name="Path 595" d="M406.484,120.612H405V124h-2v-9.479h4.432a2.95,2.95,0,0,1,3.176,3.057,2.725,2.725,0,0,1-2.074,2.82l2.133,3.555h-2.311Zm.628-4.361H405v2.619h2.109a1.315,1.315,0,1,0,0-2.619Z" transform="translate(74.527 21.177)" fill="#fff"/>
                <path id="Path_596" data-name="Path 596" d="M416.376,122.364h-3.4l-.96,1.635H409.8l5.771-9.479h7.5v1.754H418.4v2.062h4.574v1.742H418.4v2.2h4.669v1.742h-6.707Zm-2.465-1.742h2.465v-4.349Z" transform="translate(75.785 21.178)" fill="#fff"/>
                <path id="Path_597" data-name="Path 597" d="M427,114.395a4.42,4.42,0,0,1,3.922,2.074l-1.671.912A2.7,2.7,0,0,0,427,116.2a3.116,3.116,0,0,0,0,6.221,3.235,3.235,0,0,0,2.05-.723v-1.185h-2.536v-1.73h4.526V122.4A5.285,5.285,0,0,1,427,124.194a4.917,4.917,0,1,1,0-9.823Z" transform="translate(78.011 21.15)" fill="#fff"/>
                <path id="Path_598" data-name="Path 598" d="M431.519,121.151a4.135,4.135,0,0,0,3.01,1.28c1.114,0,1.647-.533,1.647-1.066s-.818-.948-1.908-1.185c-1.552-.355-3.555-.782-3.555-2.9,0-1.576,1.363-2.856,3.555-2.856a5.285,5.285,0,0,1,3.7,1.327l-1.126,1.469a4.076,4.076,0,0,0-2.714-1.031c-.9,0-1.386.4-1.386.96s.794.841,1.884,1.1c1.564.355,3.555.818,3.555,2.927,0,1.73-1.185,3.045-3.792,3.045a5.415,5.415,0,0,1-4.017-1.517Z" transform="translate(79.589 21.159)" fill="#fff"/>
                <path id="Path_599" data-name="Path 599" d="M446.4,117.174,443.729,124h-.889l-2.666-6.825V124H438.16V114.52h2.82l2.311,5.925,2.3-5.925h2.844V124H446.4Z" transform="translate(81.03 21.178)" fill="#fff"/>
                <path id="Path_600" data-name="Path 600" d="M454.1,122.364h-3.413L449.736,124H447.52l5.771-9.479H460.8v1.754h-4.669v2.062h4.574v1.742h-4.574v2.2H460.8v1.742H454.1Zm-2.453-1.742h2.477v-4.349Z" transform="translate(82.761 21.178)" fill="#fff"/>
                <path id="Path_601" data-name="Path 601" d="M463.555,120.612h-1.469V124H460v-9.479h4.432a2.95,2.95,0,0,1,3.188,3.057,2.737,2.737,0,0,1-2.074,2.82l2.133,3.555h-2.37Zm.64-4.361h-2.109v2.619h2.109a1.315,1.315,0,1,0,0-2.619Z" transform="translate(85.068 21.177)" fill="#fff"/>
                <path id="Path_602" data-name="Path 602" d="M470.511,120.208l-.747.877V124H467.75V114.52h2.014v4.242l3.353-4.242h2.488l-3.768,4.455L475.867,124h-2.488Z" transform="translate(86.502 21.178)" fill="#fff"/>
                <path id="Path_603" data-name="Path 603" d="M475.38,114.52h6.659v1.754H477.3v2.062h4.74v1.742h-4.6v2.2h4.74v1.742h-6.707Z" transform="translate(87.913 21.178)" fill="#fff"/>
                <path id="Path_604" data-name="Path 604" d="M485.876,120.612h-1.481V124H482.38v-9.479h4.432a2.939,2.939,0,0,1,3.176,3.057,2.725,2.725,0,0,1-2.074,2.82l2.133,3.555h-2.37Zm.64-4.361h-2.121v2.619h2.121a1.315,1.315,0,1,0,0-2.619Z" transform="translate(89.207 21.177)" fill="#fff"/>
                <path id="Path_605" data-name="Path 605" d="M414.115,172.581a4.444,4.444,0,0,1,3.922,2.074l-1.683.912a2.69,2.69,0,0,0-2.24-1.185,3.117,3.117,0,1,0,2.038,5.5V178.7h-2.524v-1.706h4.515v3.626a5.237,5.237,0,0,1-4.029,1.789,4.917,4.917,0,1,1,0-9.823Z" transform="translate(75.629 31.915)" fill="#fff"/>
                <path id="Path_606" data-name="Path 606" d="M421.546,178.8h-1.469v3.389H418.05v-9.479h4.432a2.95,2.95,0,0,1,3.188,3.057,2.737,2.737,0,0,1-2.074,2.82l2.133,3.555h-2.37Zm.64-4.349h-2.109v2.607h2.109a1.3,1.3,0,0,0,0-2.607Z" transform="translate(77.311 31.938)" fill="#fff"/>
                <path id="Path_607" data-name="Path 607" d="M425.79,172.71h2.05v5.628a2.192,2.192,0,1,0,4.372,0V172.71h2.05V178.4c0,2.37-1.351,3.97-4.23,3.97s-4.242-1.623-4.242-3.958Z" transform="translate(78.742 31.94)" fill="#fff"/>
                <path id="Path_608" data-name="Path 608" d="M434.52,172.71h4.444a3.045,3.045,0,1,1,0,6.091h-2.37v3.389h-2.026Zm4.171,1.742h-2.145v2.607h2.1a1.3,1.3,0,1,0,0-2.607Z" transform="translate(80.356 31.94)" fill="#fff"/>
                <path id="Path_609" data-name="Path 609" d="M442,172.71h4.432a3.045,3.045,0,1,1,0,6.091h-2.37v3.389H442Zm4.171,1.742h-2.145v2.607h2.145a1.3,1.3,0,0,0,0-2.607Z" transform="translate(81.74 31.94)" fill="#fff"/>
                <path id="Path_610" data-name="Path 610" d="M449.5,172.71h6.707v1.742h-4.74v2.062h4.6v1.73h-4.55v2.2h4.74v1.742H449.5Z" transform="translate(83.127 31.94)" fill="#fff"/>
                <path id="Path_611" data-name="Path 611" d="M456.5,172.712h4.989a2.453,2.453,0,0,1,2.725,2.37,2.109,2.109,0,0,1-1.635,2.18,2.3,2.3,0,0,1,1.825,2.37,2.5,2.5,0,0,1-2.761,2.559H456.5Zm4.55,3.815a1.055,1.055,0,1,0,0-2.1h-2.536v2.1Zm.071,3.946a1.09,1.09,0,0,0,1.185-1.126,1.1,1.1,0,0,0-1.185-1.1h-2.607v2.228Z" transform="translate(84.421 31.937)" fill="#fff"/>
                <path id="Path_612" data-name="Path 612" d="M470.65,181.091h-4.206l-.6,1.635H463.6l3.673-9.479H469.8l3.65,9.479h-2.2Zm-3.7-1.742h3.14L468.529,175Zm1.588-9.539a1.5,1.5,0,0,1,1.481,1.5,1.481,1.481,0,1,1-2.962,0A1.517,1.517,0,0,1,468.541,169.81Zm0,.841a.675.675,0,0,0-.664.664.7.7,0,0,0,.664.675.675.675,0,0,0,.664-.675.652.652,0,0,0-.664-.664Z" transform="translate(85.734 31.403)" fill="#fff"/>
                <path id="Path_613" data-name="Path 613" d="M474.674,175.826v6.363H472.66V172.71h2.074l4.4,6.138V172.71h2.014v9.479H479.2Z" transform="translate(87.41 31.94)" fill="#fff"/>
                <path id="Path_614" data-name="Path 614" d="M481.41,172.71h3.733a4.74,4.74,0,1,1,0,9.479H481.41Zm3.733,7.7a2.832,2.832,0,0,0,2.974-2.95,2.773,2.773,0,0,0-2.974-2.974h-1.718v5.925Z" transform="translate(89.028 31.94)" fill="#fff"/>
                <path id="Path_615" data-name="Path 615" d="M391.627,219.15h6.707v1.73h-4.74v2.062h4.586v1.73h-4.586v3.958H391.58Z" transform="translate(72.415 40.528)" fill="#fff"/>
                <path id="Path_616" data-name="Path 616" d="M398.47,219.15h2.05v5.628a2.192,2.192,0,1,0,4.372,0V219.15h2.05V224.9c0,2.37-1.351,3.958-4.218,3.958s-4.254-1.623-4.254-3.946Z" transform="translate(73.69 40.528)" fill="#fff"/>
                <path id="Path_617" data-name="Path 617" d="M409.224,222.255v6.375H407.21V219.15h2.121l4.384,6.138V219.15h2.014v9.479h-1.943Z" transform="translate(75.306 40.528)" fill="#fff"/>
                <path id="Path_618" data-name="Path 618" d="M418.7,224.888l-.735.877v2.915H416V219.2h2.026v4.23l3.353-4.23h2.488l-3.768,4.443,4.017,5.036h-2.488Z" transform="translate(76.932 40.537)" fill="#fff"/>
                <path id="Path_619" data-name="Path 619" d="M425.863,220.927H423.09V219.15h7.56v1.777H427.9v7.7h-2.038Z" transform="translate(78.243 40.528)" fill="#fff"/>
                <path id="Path_620" data-name="Path 620" d="M430.57,219.15h2.014v9.479H430.57Z" transform="translate(79.626 40.528)" fill="#fff"/>
                <path id="Path_621" data-name="Path 621" d="M438.423,219a4.906,4.906,0,1,1-4.953,4.894A4.74,4.74,0,0,1,438.423,219Zm0,1.789a3.116,3.116,0,1,0,2.879,3.1,2.867,2.867,0,0,0-2.879-3.081Z" transform="translate(80.162 40.499)" fill="#fff"/>
                <path id="Path_622" data-name="Path 622" d="M445.062,222.255v6.375H443V219.15h2.074l4.384,6.138V219.15h2.026v9.479h-1.955Z" transform="translate(81.925 40.528)" fill="#fff"/>
                <path id="Path_623" data-name="Path 623" d="M452.353,225.771a4.135,4.135,0,0,0,3.01,1.28c1.114,0,1.647-.521,1.647-1.066s-.818-.948-1.92-1.185c-1.54-.355-3.555-.782-3.555-2.9,0-1.576,1.363-2.856,3.555-2.856a5.285,5.285,0,0,1,3.7,1.315l-1.126,1.481a4.04,4.04,0,0,0-2.713-1.031c-.912,0-1.386.391-1.386.96s.794.841,1.884,1.09c1.564.355,3.555.829,3.555,2.927,0,1.742-1.185,3.045-3.792,3.045a5.463,5.463,0,0,1-4.029-1.5Z" transform="translate(83.437 40.507)" fill="#fff"/>
                <path id="Path_624" data-name="Path 624" d="M461.183,220.927H458.41V219.15h7.56v1.777h-2.761v7.7h-2.026Z" transform="translate(84.774 40.528)" fill="#fff"/>
                <path id="Path_625" data-name="Path 625" d="M465.88,219.15h6.707v1.73h-4.74v2.062h4.586v1.73h-4.586v2.2h4.74v1.754H465.88Z" transform="translate(86.156 40.528)" fill="#fff"/>
                <path id="Path_626" data-name="Path 626" d="M477.516,219.006a4.42,4.42,0,0,1,3.922,2.074l-1.671.9a2.7,2.7,0,0,0-2.251-1.185,3.116,3.116,0,0,0,0,6.221,3.211,3.211,0,0,0,2.05-.723v-1.185h-2.524v-1.742h4.515v3.626a5.285,5.285,0,0,1-4.041,1.789,4.906,4.906,0,1,1,0-9.811Z" transform="translate(87.401 40.494)" fill="#fff"/>
                <path id="Path_627" data-name="Path 627" d="M483.534,222.255v6.375H481.52V219.15h2.074l4.4,6.138V219.15H490v9.479h-1.991Z" transform="translate(89.048 40.528)" fill="#fff"/>
                <path id="Path_628" data-name="Path 628" d="M403.38,262.5h-4.23l-.6,1.635H396.33L400,254.656h2.524l3.661,9.479h-2.2Zm-3.7-1.754h3.14l-1.564-4.349Zm1.6-9.479a.626.626,0,1,1,0-.047Zm0,.829a.675.675,0,1,0,.586.669A.675.675,0,0,0,401.283,252.1Z" transform="translate(73.294 46.458)" fill="#fff"/>
                <path id="Path_629" data-name="Path 629" d="M408.886,260.2h-1.469v3.4H405.39v-9.479h4.432a2.951,2.951,0,0,1,3.187,3.057,2.713,2.713,0,0,1-2.074,2.808l2.133,3.614H410.7Zm.64-4.349h-2.109v2.619h2.109a1.315,1.315,0,1,0,0-2.619Z" transform="translate(74.969 46.993)" fill="#fff"/>
                <path id="Path_630" data-name="Path 630" d="M413.669,260.739a4.159,4.159,0,0,0,3.01,1.28c1.114,0,1.659-.521,1.659-1.066s-.829-.948-1.92-1.185c-1.552-.344-3.555-.77-3.555-2.891,0-1.576,1.363-2.856,3.555-2.856a5.261,5.261,0,0,1,3.7,1.315L419,256.817a4.076,4.076,0,0,0-2.725-1.043c-.9,0-1.386.4-1.386.972s.794.841,1.884,1.09c1.564.355,3.555.829,3.555,2.927,0,1.742-1.185,3.045-3.792,3.045a5.427,5.427,0,0,1-4.017-1.5Z" transform="translate(76.288 46.976)" fill="#fff"/>
                <path id="Path_631" data-name="Path 631" d="M420.871,260.739a4.159,4.159,0,0,0,3.01,1.28c1.114,0,1.659-.521,1.659-1.066s-.829-.948-1.92-1.185c-1.552-.344-3.555-.77-3.555-2.891,0-1.576,1.363-2.856,3.555-2.856a5.249,5.249,0,0,1,3.685,1.315l-1.114,1.481a4.076,4.076,0,0,0-2.714-1.043c-.912,0-1.4.4-1.4.972s.794.841,1.884,1.09c1.564.355,3.555.829,3.555,2.927,0,1.742-1.185,3.045-3.792,3.045a5.427,5.427,0,0,1-4.017-1.5Z" transform="translate(77.618 46.976)" fill="#fff"/>
                <path id="Path_632" data-name="Path 632" d="M429.7,255.9H426.93V254.12h7.56V255.9h-2.737v7.7h-2.038Z" transform="translate(78.953 46.995)" fill="#fff"/>
                <path id="Path_633" data-name="Path 633" d="M433.527,261.289a2.145,2.145,0,0,0,1.5.687,1.4,1.4,0,0,0,1.481-1.517V254.12h2.014v6.363a3.01,3.01,0,0,1-3.341,3.282,3.472,3.472,0,0,1-2.536-.924Z" transform="translate(80.011 46.995)" fill="#fff"/>
                <path id="Path_634" data-name="Path 634" d="M439.2,254.12h6.707v1.73h-4.74v2.062h4.586v1.73h-4.538v2.2h4.74V263.6H439.2Z" transform="translate(81.222 46.995)" fill="#fff"/>
                <path id="Path_635" data-name="Path 635" d="M449.686,260.2h-1.469v3.4H446.19v-9.479h4.432a2.951,2.951,0,0,1,3.187,3.057,2.714,2.714,0,0,1-2.074,2.808l2.133,3.614H451.5Zm.64-4.349h-2.109v2.619h2.109a1.315,1.315,0,1,0,0-2.619Z" transform="translate(82.515 46.993)" fill="#fff"/>
                <path id="Path_636" data-name="Path 636" d="M455.924,257.225V263.6H453.91V254.12h2.074l4.4,6.138V254.12h2.014V263.6h-1.943Z" transform="translate(83.942 46.995)" fill="#fff"/>
                <path id="Path_637" data-name="Path 637" d="M462.66,254.12h6.707v1.73h-4.74v2.062h4.586v1.73h-4.586v2.2h4.74V263.6H462.66Z" transform="translate(85.56 46.995)" fill="#fff"/>
                <path id="Path_638" data-name="Path 638" d="M477.282,254a4.906,4.906,0,1,1-4.941,4.894A4.74,4.74,0,0,1,477.282,254Zm0,1.789a3.116,3.116,0,1,0,2.891,3.1,2.856,2.856,0,0,0-2.891-3.116Z" transform="translate(87.35 46.972)" fill="#fff"/>
                <path id="Path_639" data-name="Path 639" d="M486.546,254a4.444,4.444,0,0,1,3.922,2.074l-1.683.9a2.678,2.678,0,0,0-2.24-1.185,3.116,3.116,0,0,0,0,6.221,3.235,3.235,0,0,0,2.05-.723V260.1H486.06v-1.718h4.515v3.626a5.237,5.237,0,0,1-4.029,1.789,4.906,4.906,0,0,1,0-9.811Z" transform="translate(89.07 46.971)" fill="#fff"/>
                <path id="Path_640" data-name="Path 640" d="M405.425,271.174,402.759,278h-.889l-2.666-6.825V278H397.19V268.52h2.82l2.3,5.925,2.311-5.925h2.844V278h-2.038Z" transform="translate(73.453 49.658)" fill="#fff"/>
                <path id="Path_641" data-name="Path 641" d="M416.966,273.369A4.74,4.74,0,0,1,412,278.274a5.143,5.143,0,0,1-2.548-.64l-.829,1.185-.806-.592.818-1.185a4.811,4.811,0,0,1-1.576-3.685A4.74,4.74,0,0,1,412,268.463a5.119,5.119,0,0,1,2.441.569l.794-1.1.794.581-.77,1.055A4.811,4.811,0,0,1,416.966,273.369Zm-7.11,2.074,3.555-4.882a3.044,3.044,0,0,0-1.339-.3,2.856,2.856,0,0,0-2.868,3.1,3.294,3.294,0,0,0,.6,2.074Zm5.083-2.074a3.318,3.318,0,0,0-.77-2.2l-3.555,4.953a2.927,2.927,0,0,0,4.337-2.749Z" transform="translate(75.278 49.549)" fill="#fff"/>
                <path id="Path_642" data-name="Path 642" d="M416.62,268.52h3.744a4.74,4.74,0,0,1,0,9.479H416.62Zm3.744,7.7a2.832,2.832,0,0,0,2.962-2.962,2.761,2.761,0,0,0-2.962-2.962h-1.718v5.925Z" transform="translate(77.046 49.658)" fill="#fff"/>
                <path id="Path_643" data-name="Path 643" d="M425.24,268.52h6.707v1.754h-4.74v2.062h4.6v1.73h-4.6V276.2h4.74v1.754H425.24Z" transform="translate(78.64 49.658)" fill="#fff"/>
                <path id="Path_644" data-name="Path 644" d="M434.989,274.2l-.735.889V278H432.24V268.52h2.014v4.23l3.353-4.23H440.1l-3.768,4.443L440.345,278H437.88Z" transform="translate(79.935 49.658)" fill="#fff"/>
                <path id="Path_645" data-name="Path 645" d="M441.884,271.625V278H439.87V268.52h2.074l4.4,6.138V268.52h1.979V278h-1.943Z" transform="translate(81.346 49.658)" fill="#fff"/>
                <path id="Path_646" data-name="Path 646" d="M454.809,276.364h-4.23L449.986,278H447.77l3.661-9.479h2.536L457.629,278h-2.2Zm-3.685-1.754h3.14l-1.564-4.337Z" transform="translate(82.807 49.658)" fill="#fff"/>
                <path id="Path_647" data-name="Path 647" d="M456.82,268.521h4.443a3.045,3.045,0,1,1,0,6.079h-2.37V278h-2.026Zm4.171,1.73h-2.145v2.619h2.145a1.315,1.315,0,1,0,0-2.619Z" transform="translate(84.48 49.657)" fill="#fff"/>
                <path id="Path_648" data-name="Path 648" d="M373.865,332.221a4.443,4.443,0,0,1,3.922,2.074l-1.683.912a2.654,2.654,0,0,0-2.24-1.185,3.116,3.116,0,0,0,0,6.221,3.235,3.235,0,0,0,2.05-.723v-1.185h-2.536v-1.73h4.515v3.65a5.237,5.237,0,0,1-4.029,1.789,4.917,4.917,0,1,1,0-9.823Z" transform="translate(68.185 61.437)" fill="#fff"/>
                <path id="Path_649" data-name="Path 649" d="M383.94,340.224H379.7l-.592,1.635H376.89l3.661-9.479h2.536l3.65,9.479h-2.2Zm-3.7-1.742h3.14l-1.564-4.349Z" transform="translate(69.699 61.468)" fill="#fff"/>
                <path id="Path_650" data-name="Path 650" d="M394.176,335l-2.666,6.825h-.877L387.955,335v6.825H386V332.35h2.832l2.3,5.925,2.3-5.925h2.844v9.479h-2.1Z" transform="translate(71.384 61.462)" fill="#fff"/>
                <path id="Path_651" data-name="Path 651" d="M404.445,335l-2.666,6.825H400.9L398.224,335v6.825H396.21V332.35h2.832l2.3,5.925,2.3-5.925h2.844v9.479h-2.038Z" transform="translate(73.272 61.462)" fill="#fff"/>
                <path id="Path_652" data-name="Path 652" d="M406.47,332.35h6.707v1.73h-4.74v2.062h4.586v1.718h-4.586v2.2h4.74v1.742H406.47Z" transform="translate(75.169 61.462)" fill="#fff"/>
                <path id="Path_653" data-name="Path 653" d="M413.46,332.35h2.026v7.7h4.005v1.777H413.46Z" transform="translate(76.462 61.462)" fill="#fff"/>
                <path id="Path_654" data-name="Path 654" d="M431.057,335l-2.678,6.825H427.5L424.836,335v6.825H422.81V332.35h2.832l2.3,5.925,2.311-5.925h2.832v9.479h-2.026Z" transform="translate(78.191 61.462)" fill="#fff"/>
                <path id="Path_655" data-name="Path 655" d="M442.6,337.211a4.74,4.74,0,0,1-4.965,4.894,5.225,5.225,0,0,1-2.559-.64l-.818,1.185-.818-.6.829-1.185a4.953,4.953,0,0,1,3.365-8.579,5.178,5.178,0,0,1,2.429.569l.794-1.09.806.581-.77,1.043A4.846,4.846,0,0,1,442.6,337.211Zm-7.169,2.074,3.555-4.894a3.27,3.27,0,0,0-1.339-.3,2.867,2.867,0,0,0-2.867,3.116,3.341,3.341,0,0,0,.652,2.074Zm5.1-2.074a3.294,3.294,0,0,0-.77-2.2l-3.555,4.941a2.737,2.737,0,0,0,1.446.367,2.879,2.879,0,0,0,2.879-3.1Z" transform="translate(80.018 61.353)" fill="#fff"/>
                <path id="Path_656" data-name="Path 656" d="M442.25,332.35h3.744a4.74,4.74,0,1,1,0,9.479H442.25Zm3.744,7.7a2.832,2.832,0,0,0,2.962-2.95,2.773,2.773,0,0,0-2.962-2.974h-1.671v5.925Z" transform="translate(81.786 61.462)" fill="#fff"/>
                <path id="Path_657" data-name="Path 657" d="M450.87,332.35h6.707v1.73h-4.74v2.062h4.586v1.718h-4.586v2.2h4.74v1.742H450.87Z" transform="translate(83.38 61.462)" fill="#fff"/>
                <path id="Path_658" data-name="Path 658" d="M460.621,338.038l-.735.877v2.915H457.86V332.35h2.026v4.23l3.353-4.23h2.488l-3.768,4.444,4.017,5.036h-2.488Z" transform="translate(84.673 61.462)" fill="#fff"/>
                <path id="Path_659" data-name="Path 659" d="M467.514,335.486v6.363H465.5V332.37h2.074l4.384,6.138V332.37h2.026v9.479h-1.967Z" transform="translate(86.086 61.466)" fill="#fff"/>
                <path id="Path_660" data-name="Path 660" d="M480.44,340.224h-4.23l-.6,1.635H473.39l3.673-9.479h2.524l3.65,9.479h-2.192Zm-3.7-1.742h3.14l-1.564-4.349Z" transform="translate(87.545 61.468)" fill="#fff"/>
                <path id="Path_661" data-name="Path 661" d="M482.45,332.351h4.432a3.045,3.045,0,1,1,0,6.079h-2.37v3.4H482.5Zm4.171,1.73h-2.157v2.6h2.192a1.3,1.3,0,1,0,0-2.6Z" transform="translate(89.22 61.461)" fill="#fff"/>
            </svg>
        </div>
        <div class="col4">
            <h5>
                {{ form.uniformtitle }}
            </h5>
            <p>
                {{ form.uniformtext }}
            </p>
        </div>
    </section>
  </div>
  <bottom/>
</template>

<script>
// @ is an alias to /src
import bottom from '@/components/bottom.vue'
import topping from '@/components/front-end-topping.vue'

import { reactive, onMounted } from 'vue'
import { getPracticalpage } from '../main'


export default {
    setup() {
        onMounted(async () => {
            const practicalpage = await getPracticalpage()
            form.hyttetitle = practicalpage.hyttetitle
            form.hyttetext = practicalpage.hyttetext
            form.uniformtitle = practicalpage.uniformtitle
            form.uniformtext = practicalpage.uniformtext
        })

        const form = reactive({ 
            
        })

        return { 
            form
        }
    },

    name: 'Praktisk',
    components: {
        topping,
        bottom
    }
}
</script>
<style lang="scss" scoped>
    .praktisk{
        background: var(--whiteshade3);
    }

    .container{
        display: flex;
    }

    .hytte{
        padding-top: 50px;
        margin-bottom: 50px;
        h5{
            font-size: 32px;
            margin-bottom: 10px;
        }
        p{
            padding-right: 1em;
        }
    }

    .map{
        margin-bottom: 50px;
    }

    .uniform{
        h5{
            font-size: 32px;
            margin-bottom: 10px;
        }
        div{
            padding-right: 1em;
        }
        padding-bottom: 50px;
    }

    @media screen and (max-width: 800px) {
        .container{
            flex-direction: column-reverse;
        }

        h5{
            margin-top: 50px;
        }
        p{
            margin-bottom: 50px;
        }
    }
</style>